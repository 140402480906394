.react-viewer {
	opacity: 0;
}

.react-viewer-inline {
	position: relative;
	width: 100%;
	height: 100%;
}

.react-viewer li {
	list-style: none;
}

.react-viewer-mask {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	// background-color: #373737;
	// background-color: rgba(55, 55, 55, 0.6);
	height: 100%;
	filter: alpha(opacity=50);
	z-index: 1000;
	border-radius: 8px;
	padding-top: 4rem;
}

.react-viewer-close {
	position: fixed;
	top: 0;
	right: 0;
	overflow: hidden;
	width: 40px;
	height: 40px;
	border-radius: 0 0 0 40px;
	cursor: pointer;
	z-index: 1010;
}

.react-viewer-close > i {
	position: relative;
	top: 4px;
	left: 18px;
}

.react-viewer-canvas {
	border-radius: 8px;
	position: fixed;
	top: 90px;
	right: 0;
	left: 0;
	bottom: 90px;
	overflow: hidden;
	z-index: 1005;
}

.react-viewer-canvas > img {
	display: block;
	width: auto;
	height: auto;
	user-select: none;
	background:
		linear-gradient(
			45deg,
			rgba(0, 0, 0, 0.0980392) 25%,
			rgba(0, 0, 0, 0) 25%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.0980392) 75%,
			rgba(0, 0, 0, 0.0980392) 0
		),
		linear-gradient(
			45deg,
			rgba(0, 0, 0, 0.0980392) 25%,
			rgba(0, 0, 0, 0) 25%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.0980392) 75%,
			rgba(0, 0, 0, 0.0980392) 0
		),
		rgb(255, 255, 255);
	background-position: 0 0, 10px 10px;
	background-origin: padding-box;
	background-clip: border-box;
	background-size: 20px 20px;
}

.react-viewer-canvas > img.drag {
	cursor: move;
}

.react-viewer-footer {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	text-align: center;
	z-index: 1005;

	* {
		color: white;
	}
}

.react-viewer-inline > .react-viewer-mask,
.react-viewer-inline > .react-viewer-close,
.react-viewer-inline > .react-viewer-canvas,
.react-viewer-inline > .react-viewer-footer {
	position: absolute;
}

.react-viewer-attribute {
	margin: 0;
	margin-bottom: 6px;
	color: #fff;
}

.react-viewer-toolbar {
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.28);
	padding: 1em;
	border-radius: 2px;
	display: inline-block;
}

.react-viewer-toolbar li {
	display: inline-block;
	// margin-right: .5em;
	cursor: pointer;
}

.react-viewer li.empty {
	background-color: transparent;
	cursor: default;
}

.react-viewer-navbar {
	display: none;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.5);
}

.react-viewer-list {
	height: 50px;
	padding: 1px;
}

.react-viewer-list > li {
	display: inline-block;
	width: 30px;
	height: 50px;
	cursor: pointer;
	overflow: hidden;
	margin-right: 1px;
}

.react-viewer-list > li > img {
	width: 60px;
	height: 50px;
	margin-left: -15px;
	opacity: 0.5;
}

.react-viewer-list > li.active > img {
	opacity: 1;
}

.react-viewer-transition {
	-webkit-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

.react-viewer-image-transition {
	-webkit-transition-property: width, height, margin, -webkit-transform;
	transition-property: width, height, margin, -webkit-transform;
	transition-property: width, height, margin, transform;
	transition-property: width, height, margin, transform, -webkit-transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.react-viewer-list-transition {
	-webkit-transition: margin 0.3s ease-out;
	transition: margin 0.3s ease-out;
}

.react-viewer-icon {
	font-family: 'Material Icons';
	display: inline-block;
	font-style: normal;
	vertical-align: middle;
	text-align: center;
	text-transform: none;
	line-height: 1;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	font-size: 22px;
	margin: 0 0.5em;
}

.react-viewer-icon-download,
.react-viewer-icon-prev,
.react-viewer-icon-next,
.react-viewer-icon-close,
.react-viewer-icon-reset {
	display: none !important;
}

.react-viewer-icon-zoomIn::before {
	content: 'zoom_in';
}

.react-viewer-icon-zoomOut::before {
	content: 'zoom_out';
}

.react-viewer-icon-rotateLeft::before {
	content: 'rotate_left';
}

.react-viewer-icon-rotateRight::before {
	content: 'rotate_right';
}

.react-viewer-icon-scaleX::before {
	content: 'flip';
}

.react-viewer-icon-scaleY::before {
	content: 'flip';
	transform: rotate(90deg);
	display: block;
}

.spin {
	color: #2db7f5;
	vertical-align: middle;
	text-align: center;
	opacity: 0;
	position: absolute;
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	font-size: 12px;
	display: none;
}

.spin-spinning {
	opacity: 1;
	position: static;
	display: inline-block;
}

.spin-dot {
	position: relative;
	display: block;
	width: 20px;
	height: 20px;
}

.spin-dot::after,
.spin-dot::before {
	content: '';
	border-radius: 50%;
	background-color: #2db7f5;
	-webkit-animation: antSpinBounce 2.2s infinite ease-in-out;
	animation: antSpinBounce 2.2s infinite ease-in-out;
	display: block;
	position: absolute;
	opacity: 0.5;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.spin-dot::after,
.spin-dot::before {
	content: '';
	border-radius: 50%;
	background-color: #2db7f5;
	-webkit-animation: antSpinBounce 2.2s infinite ease-in-out;
	animation: antSpinBounce 2.2s infinite ease-in-out;
	display: block;
	position: absolute;
	opacity: 0.5;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.spin-dot::after {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

@-webkit-keyframes antSpinBounce {
	0%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes antSpinBounce {
	0%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
