.cursor {
	cursor: pointer;
}

.move {
	cursor: ns-resize;
}

.drag {
	opacity: 0.25;
}

.role {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	background-color: #f7f7f9;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.roleClickable {
	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.1);
	}
}
