.f32 {

    ._African_Union{background-position:0 -32px;}
    ._Arab_League{background-position:0 -64px;}
    ._ASEAN{background-position:0 -96px;}
    ._CARICOM{background-position:0 -128px;}
    ._CIS{background-position:0 -160px;}
    ._Commonwealth{background-position:0 -192px;}
    ._England{background-position:0 -224px;}
    ._European_Union{background-position:0 -256px;}
    ._Islamic_Conference{background-position:0 -288px;}
    ._Kosovo{background-position:0 -320px;}
    ._NATO{background-position:0 -352px;}
    ._Northern_Cyprus{background-position:0 -384px;}
    ._Northern_Ireland{background-position:0 -416px;}
    ._Olimpic_Movement{background-position:0 -448px;}
    ._OPEC{background-position:0 -480px;}
    ._Red_Cross{background-position:0 -512px;}
    ._Scotland{background-position:0 -544px;}
    ._Somaliland{background-position:0 -576px;}
    ._Tibet{background-position:0 -608px;}
    ._United_Nations{background-position:0 -640px;}
    ._Wales{background-position:0 -672px;}

    .eu{background-position:0 -256px;}

    .ad{background-position:0 -704px;}
    .ae{background-position:0 -736px;}
    .af{background-position:0 -768px;}
    .ag{background-position:0 -800px;}
    .ai{background-position:0 -832px;}
    .al{background-position:0 -864px;}
    .am{background-position:0 -896px;}
    .ao{background-position:0 -928px;}
    .aq{background-position:0 -960px;}
    .ar{background-position:0 -992px;}
    .as{background-position:0 -1024px;}
    .at{background-position:0 -1056px;}
    .au{background-position:0 -1088px;}
    .aw{background-position:0 -1120px;}
    .ax{background-position:0 -1152px;}
    .az{background-position:0 -1184px;}
    .ba{background-position:0 -1216px;}
    .bb{background-position:0 -1248px;}
    .bd{background-position:0 -1280px;}
    .be{background-position:0 -1312px;}
    .bf{background-position:0 -1344px;}
    .bg{background-position:0 -1376px;}
    .bh{background-position:0 -1408px;}
    .bi{background-position:0 -1440px;}
    .bj{background-position:0 -1472px;}
    .bm{background-position:0 -1504px;}
    .bn{background-position:0 -1536px;}
    .bo{background-position:0 -1568px;}
    .br{background-position:0 -1600px;}
    .bs{background-position:0 -1632px;}
    .bt{background-position:0 -1664px;}
    .bw{background-position:0 -1696px;}
    .by{background-position:0 -1728px;}
    .bz{background-position:0 -1760px;}
    .ca{background-position:0 -1792px;}
    .cd{background-position:0 -1824px;}
    .cf{background-position:0 -1856px;}
    .cg{background-position:0 -1888px;}
    .ch{background-position:0 -1920px;}
    .ci{background-position:0 -1952px;}
    .ck{background-position:0 -1984px;}
    .cl{background-position:0 -2016px;}
    .cm{background-position:0 -2048px;}
    .cn{background-position:0 -2080px;}
    .co{background-position:0 -2112px;}
    .cr{background-position:0 -2144px;}
    .cu{background-position:0 -2176px;}
    .cv{background-position:0 -2208px;}
    .cy{background-position:0 -2240px;}
    .cz{background-position:0 -2272px;}
    .de{background-position:0 -2304px;}
    .dj{background-position:0 -2336px;}
    .dk{background-position:0 -2368px;}
    .dm{background-position:0 -2400px;}
    .do{background-position:0 -2432px;}
    .dz{background-position:0 -2464px;}
    .ec{background-position:0 -2496px;}
    .ee{background-position:0 -2528px;}
    .eg{background-position:0 -2560px;}
    .eh{background-position:0 -2592px;}
    .er{background-position:0 -2624px;}
    .es{background-position:0 -2656px;}
    .et{background-position:0 -2688px;}
    .fi{background-position:0 -2720px;}
    .fj{background-position:0 -2752px;}
    .fm{background-position:0 -2784px;}
    .fo{background-position:0 -2816px;}
    .fr{background-position:0 -2848px;}  .bl{background-position:0 -2848px;}  .cp{background-position:0 -2848px;}  .mf{background-position:0 -2848px;}  .yt{background-position:0 -2848px;}
    .ga{background-position:0 -2880px;}
    .gb{background-position:0 -2912px;}  .sh{background-position:0 -2912px;}
    .gd{background-position:0 -2944px;}
    .ge{background-position:0 -2976px;}
    .gg{background-position:0 -3008px;}
    .gh{background-position:0 -3040px;}
    .gi{background-position:0 -3072px;}
    .gl{background-position:0 -3104px;}
    .gm{background-position:0 -3136px;}
    .gn{background-position:0 -3168px;}
    .gp{background-position:0 -3200px;}
    .gq{background-position:0 -3232px;}
    .gr{background-position:0 -3264px;}
    .gt{background-position:0 -3296px;}
    .gu{background-position:0 -3328px;}
    .gw{background-position:0 -3360px;}
    .gy{background-position:0 -3392px;}
    .hk{background-position:0 -3424px;}
    .hn{background-position:0 -3456px;}
    .hr{background-position:0 -3488px;}
    .ht{background-position:0 -3520px;}
    .hu{background-position:0 -3552px;}
    .id{background-position:0 -3584px;}
    .mc{background-position:0 -3584px;}
    .ie{background-position:0 -3616px;}
    .il{background-position:0 -3648px;}
    .im{background-position:0 -3680px;}
    .in{background-position:0 -3712px;}
    .iq{background-position:0 -3744px;}
    .ir{background-position:0 -3776px;}
    .is{background-position:0 -3808px;}
    .it{background-position:0 -3840px;}
    .je{background-position:0 -3872px;}
    .jm{background-position:0 -3904px;}
    .jo{background-position:0 -3936px;}
    .jp{background-position:0 -3968px;}
    .ke{background-position:0 -4000px;}
    .kg{background-position:0 -4032px;}
    .kh{background-position:0 -4064px;}
    .ki{background-position:0 -4096px;}
    .km{background-position:0 -4128px;}
    .kn{background-position:0 -4160px;}
    .kp{background-position:0 -4192px;}
    .kr{background-position:0 -4224px;}
    .kw{background-position:0 -4256px;}
    .ky{background-position:0 -4288px;}
    .kz{background-position:0 -4320px;}
    .la{background-position:0 -4352px;}
    .lb{background-position:0 -4384px;}
    .lc{background-position:0 -4416px;}
    .li{background-position:0 -4448px;}
    .lk{background-position:0 -4480px;}
    .lr{background-position:0 -4512px;}
    .ls{background-position:0 -4544px;}
    .lt{background-position:0 -4576px;}
    .lu{background-position:0 -4608px;}
    .lv{background-position:0 -4640px;}
    .ly{background-position:0 -4672px;}
    .ma{background-position:0 -4704px;}
    .md{background-position:0 -4736px;}
    .me{background-position:0 -4768px;}
    .mg{background-position:0 -4800px;}
    .mh{background-position:0 -4832px;}
    .mk{background-position:0 -4864px;}
    .ml{background-position:0 -4896px;}
    .mm{background-position:0 -4928px;}
    .mn{background-position:0 -4960px;}
    .mo{background-position:0 -4992px;}
    .mq{background-position:0 -5024px;}
    .mr{background-position:0 -5056px;}
    .ms{background-position:0 -5088px;}
    .mt{background-position:0 -5120px;}
    .mu{background-position:0 -5152px;}
    .mv{background-position:0 -5184px;}
    .mw{background-position:0 -5216px;}
    .mx{background-position:0 -5248px;}
    .my{background-position:0 -5280px;}
    .mz{background-position:0 -5312px;}
    .na{background-position:0 -5344px;}
    .nc{background-position:0 -5376px;}
    .ne{background-position:0 -5408px;}
    .ng{background-position:0 -5440px;}
    .ni{background-position:0 -5472px;}
    .nl{background-position:0 -5504px;}  .bq{background-position:0 -5504px;}
    .no{background-position:0 -5536px;}  .bv{background-position:0 -5536px;}  .nq{background-position:0 -5536px;}  .sj{background-position:0 -5536px;}
    .np{background-position:0 -5568px;}
    .nr{background-position:0 -5600px;}
    .nz{background-position:0 -5632px;}
    .om{background-position:0 -5664px;}
    .pa{background-position:0 -5696px;}
    .pe{background-position:0 -5728px;}
    .pf{background-position:0 -5760px;}
    .pg{background-position:0 -5792px;}
    .ph{background-position:0 -5824px;}
    .pk{background-position:0 -5856px;}
    .pl{background-position:0 -5888px;}
    .pr{background-position:0 -5920px;}
    .ps{background-position:0 -5952px;}
    .pt{background-position:0 -5984px;}
    .pw{background-position:0 -6016px;}
    .py{background-position:0 -6048px;}
    .qa{background-position:0 -6080px;}
    .re{background-position:0 -6112px;}
    .ro{background-position:0 -6144px;}
    .rs{background-position:0 -6176px;}
    .ru{background-position:0 -6208px;}
    .rw{background-position:0 -6240px;}
    .sa{background-position:0 -6272px;}
    .sb{background-position:0 -6304px;}
    .sc{background-position:0 -6336px;}
    .sd{background-position:0 -6368px;}
    .se{background-position:0 -6400px;}
    .sg{background-position:0 -6432px;}
    .si{background-position:0 -6464px;}
    .sk{background-position:0 -6496px;}
    .sl{background-position:0 -6528px;}
    .sm{background-position:0 -6560px;}
    .sn{background-position:0 -6592px;}
    .so{background-position:0 -6624px;}
    .sr{background-position:0 -6656px;}
    .st{background-position:0 -6688px;}
    .sv{background-position:0 -6720px;}
    .sy{background-position:0 -6752px;}
    .sz{background-position:0 -6784px;}
    .tc{background-position:0 -6816px;}
    .td{background-position:0 -6848px;}
    .tg{background-position:0 -6880px;}
    .th{background-position:0 -6912px;}
    .tj{background-position:0 -6944px;}
    .tl{background-position:0 -6976px;}
    .tm{background-position:0 -7008px;}
    .tn{background-position:0 -7040px;}
    .to{background-position:0 -7072px;}
    .tr{background-position:0 -7104px;}
    .tt{background-position:0 -7136px;}
    .tv{background-position:0 -7168px;}
    .tw{background-position:0 -7200px;}
    .tz{background-position:0 -7232px;}
    .ua{background-position:0 -7264px;}
    .ug{background-position:0 -7296px;}
    .us{background-position:0 -7328px;}
    .uy{background-position:0 -7360px;}
    .uz{background-position:0 -7392px;}
    .va{background-position:0 -7424px;}
    .vc{background-position:0 -7456px;}
    .ve{background-position:0 -7488px;}
    .vg{background-position:0 -7520px;}
    .vi{background-position:0 -7552px;}
    .vn{background-position:0 -7584px;}
    .vu{background-position:0 -7616px;}
    .ws{background-position:0 -7648px;}
    .ye{background-position:0 -7680px;}
    .za{background-position:0 -7712px;}
    .zm{background-position:0 -7744px;}
    .zw{background-position:0 -7776px;}
    .sx{background-position:0 -7808px;}
    .cw{background-position:0 -7840px;}
    .ss{background-position:0 -7872px;}
    .nu{background-position:0 -7904px;}

}
