.fakeFilm {
	max-width: 700px;
	position: relative;

	&::after {
		content: '';
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom:0;
		border-radius: 3px;
	}

	&::before {
		content: '';
		padding-top: 44%;
		display: block;
	}

	> div > div {
		height: 33%;
		position: relative;
		z-index: 1;

		a {
			&:hover {
				outline: solid white;
			}
		}
	}
}
