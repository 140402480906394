@import 'temp_rc-slider.scss';
@import 'viewer';
@import 'flags';
@import 'day_picker';
@import 'datepicker.scss';

body {
	background: rgb(249,249, 249);
}

.max-width-5 {
	max-width: 80rem;
}

.tether-element {
	z-index: 10000;
}

input {
	min-width: 0px;
	&[type='file'] {
		width: 0;
	}
}
/// Material icons resets
.large-icon {
	font-size: 44px;
}

//// to do - add to @bitcine/cs-theme
.sidebar-link {
	padding: 0.5rem 0.5rem 0.5rem 1.5rem;
	display: block;
	border-left: 6px solid transparent;

	&:not(.active-sidebar-link):hover {
		border-left-color: lighten(#fb0f3b, 30%);
		font-weight: bold;
	}

	&.active-sidebar-link {
		font-weight: bold;
		border-left-color: #fb0f3b;
		position: relative;
	}
}

.orange {
	color: #fbad46;
}

// Override slick slider position dots color
.slick-dots li button:before {
	color: #fff !important;
}

button.slick-arrow.slick-next,
button.slick-arrow.slick-prev {
	z-index: 1000;
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
	border-radius: 20px;
	background: #fff;
	line-height: 40px;
	opacity: 0.8;
	transition: opacity 250ms;
}

button.slick-arrow.slick-next:hover,
button.slick-arrow.slick-prev:hover {
	opacity: 1;
}


button.slick-arrow.slick-next span,
button.slick-arrow.slick-prev span {
	color: #000;
	line-height: 40px;
}

button.slick-arrow.slick-next { right: 0; }
button.slick-arrow.slick-prev { left: 0; }

.slick-next, .slick-prev {
	width: 40px !important;
	height: 40px !important;
}

button.slick-arrow.slick-next:before,
button.slick-arrow.slick-prev:before {
	display: none !important;
}

button.slick-arrow.slick-next:disabled,
button.slick-arrow.slick-prev:disabled {
	opacity: 0.2 !important;
	cursor: default;
}

.slick-dots {
	bottom: -32px !important;
}
///

.material-icons {
	&.block {
		display: block !important;
	}

	&.flex {
		display: flex !important;
	}

	&.large-icon {
		font-size: 44px !important;
	}
}

.row-reverse {
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.transform-flat {
	// Fixes a bug in webkit with transform and z-index
	transform-style: flat;
	-webkit-transform-style: flat;
	-moz-transform-style: flat;
}

.cursor-move {
	cursor: move;
}

.rowActionArea {
	width: 75px;
}

.arrow {
	top: -0.25rem;
}

.small-input {
	height: 28px;
	font-size: 14px;
}

button.material-icons,
button .material-icons {
	font-size: 24px;
}

table tbody tr td > button {
	vertical-align: middle;
}

input.no-min-width,
.Select.no-min-width .Select-control {
	min-width: 0;
}

.Select.error .Select-control {
	border-color: #fb0f3b;
}
/// TEMP - MOVE TO @bitcine/cs-theme
.Select--multi.has-value .Select-multi-value-wrapper {
	.Select-input {
		height: 24px !important;
	}
}

.Select--multi .Select-multi-value-wrapper {
	.Select-input {
		height: 35px !important;
	}

	display: inline-block;
	padding-top: 3px !important;

	.Select-value {
		height: 26px !important;
	}
}
///
.disabled {
	pointer-events: none;
	opacity: 0.5;
	cursor: not-allowed;
}

.ghost {
	animation-duration: 3s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
	background-size: 1200px 104px;
	position: relative;
}

.has-value .Select-multi-value-wrapper {
	padding: 4px 0 0;
}

.has-value .Select-multi-value-wrapper .Select-input {
	height: 24px;
	transform: translateY(-11px);

	input {
		height: 24px;
	}
}

.Select--multi {
	$height: 32px;

	.Select-control {
		min-height: 40px;
		height: auto;
		max-height: 200px;
		overflow: auto;
	}

	.Select-value {
		display: inline-block;
		color: #fff;
		margin: 0 4px;
		height: $height;

		&::before,
		&::after {
			content: ' ';
			display: table;
		}

		&::after {
			clear: both;
		}

		.Select-value-icon {
			display: block;
			float: left;
			padding: 0 4px;
			background: #535c63;
			font-size: 1.2em;
			font-weight: bold;
			cursor: pointer;
			height: $height;
			width: 24px;
			line-height: $height;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			text-align: center;
		}

		.Select-value-icon:hover {
			background: darken(#535c63, 10%);
		}

		.Select-value-label {
			display: block;
			float: left;
			height: $height;
			line-height: $height;
			background: #535c63;
			padding: 0 10px 0 4px;
			font-size: 1em;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}

	.Select-clear-zone,
	.Select-arrow-zone {
		display: none;
	}
}

@keyframes :global(placeHolderShimmer) {
	0% {
		background-position: -1200px 0;
	}

	100% {
		background-position: 1200px 0;
	}
}

.border-green {
	border-color: green;
}
.bmpui-ui-hugeplaybacktogglebutton, .bmpui-ui-hugereplaybutton {
	background: transparent;
	&:hover,
	&:focus {
		background: transparent;
	}
}

.bmpui-ui-skin-modern
.bmpui-ui-seekbar
.bmpui-seekbar
.bmpui-seekbar-playbackposition,
.bmpui-ui-skin-modern
.bmpui-ui-volumeslider
.bmpui-seekbar
.bmpui-seekbar-playbackposition {
	background-color: #fb0f3b !important;
}

.bmpui-seekbar-playbackposition-marker {
	background-image: none !important;
}

.bmpui-seekbar-playbackposition-marker {
	background-image: none;
	background-color: #fb0f3b !important;
	border-radius: 50%;
}

.bmpui-ui-buffering-overlay .bmpui-container-wrapper {
	div {
		display: none !important;
	}

	display: inline-block;
	height: 44px;
	width: 44px;
	border: 2px solid transparent;
	border-color: #ededed;
	border-right-color: red;
	border-radius: 50%;
	animation: spin 0.8s linear infinite;
	left: 50%;
	top: 50%;
	position: absolute;
	margin-top: -22px;
	margin-left: -22px;
	padding: 0 !important;
}


@keyframes :global(__spinnerAnimation) {
	0% {
		transform: rotate(0deg);
	}
	30% {
		transform: rotate(200deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes :global(spin) {
	0% {
		transform: rotate(0deg);
	}

	30% {
		transform: rotate(200deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

ul.cs-paginate {
	li {
		&.break {
			display: block;
			background: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
			padding: 0 0.75rem;
			border-top: 1px solid #c7cdcf;
			border-bottom: 1px solid #c7cdcf;
			border-left: 1px solid #c7cdcf;
		}

		a {
			cursor: pointer;
			background: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
			display: block;
		}

		&.previous {
			margin-right: 1rem;
		}

		&.next {
			margin-left: 1rem;
		}

		&.selected {
			a {
				background: linear-gradient(0deg, #d9d9d9 0%, #f3f3f3 100%);
				font-weight: bold;
			}
		}

		&.next,
		&.previous {
			a {
				border-radius: 3px;
				border: 1px solid #c7cdcf;
				color: #424a50;
				font-weight: bold;
				padding: 0 0.5rem;
			}
		}

		&:not(.next),
		&:not(.previous) {
			a {
				padding: 0 1rem;
				border-top: 1px solid #c7cdcf;
				border-bottom: 1px solid #c7cdcf;
				border-left: 1px solid #c7cdcf;
			}
		}

		&:nth-child(2) {
			a {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}

		&:nth-last-child(2) {
			a {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
				border-right: 1px solid #c7cdcf;
			}
		}
	}
}
