.screenerRoomCard {
	position: relative;
	width: 100%;
	padding-top: 56.25%; // Keeps 16:9 aspect ratio
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

	&:hover {
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}

	.inner,
	.slideShow,
	.slideShow .slide,
	.grad,
	.actions {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 5px;
	}

	.slideShow {
		z-index: 1;

		.slide {
			opacity: 0;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			transition: opacity 500ms;

			&.visible {
				opacity: 1;
			}
		}
	}

	.inner {
		z-index: 3;

		a {
			color: #fff;
			font-weight: bold;
			font-size: 1.4em;
		}
	}

	.actions {
		z-index: 3;

		.menu {
			position: relative;
			// display: none;
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	&:hover {
		.menu {
			opacity: 1;
		}
	}

	.menu {
		opacity: 0;
	}
}

.option {
	color: white !important;
	&:hover {
		color: black !important;
	}
}
